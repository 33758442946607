
import Logo from "@/components/Logo/index.vue"
import { Component, Vue } from 'vue-property-decorator'
import store from "@/store";
import router from "@/router";
import { Authentication } from "@/network/api";
import handleError from "@/utils/handleError";
// import handleError from "@/utils/handleError";
// import { ApplicationError } from "@/api-client/api";

@Component({
  name: 'Login',
  components: {
    Logo
  },
})
export default class extends Vue {
  confirmForm = {
    email: ""
  }
  code = "";
  done = false;
  loading = false

  errors: Array<string> | null = null;

  private validateEmail = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Email is required'))
    }
    else {
      callback()
    }
  }

  public confirmRules = {
    email: [{ validator: this.validateEmail, trigger: 'blur' }]
  }

  created() {
    let queries = this.$route.query as any;
    console.log(queries)
    if (queries.code != null) {
      this.code = unescape(queries.code) as string;
    }

    if(queries.email) {
      this.confirmForm.email = queries.email
    }

    // var params = router.currentRoute.value.params as any;
    if (queries.sent == 'success') {
      this.done = true;
    }
  }

  async resendEmail() {
    this.errors = null;

    // if (!this.done) {
    // this.loading = true
    // await Authentication.identityAuthenticationResendEmailConfirmationPost(this.confirmForm.email)
    //   .then((res) => {
    //     if (res.data.succeeded) {
    //       console.log("success resend", res);
    //       this.$message({ type: "success", message: 'Verification Email Resent.', duration: 2000 });
    //       this.done = true;
    //     }
    //     this.loading = false
    //   })
    //   .catch((error) => {
    //     this.loading = false;
    //     handleError(error)
        
    //   });
    // } else {
    //   this.done = false;
    //   this.loading = false
    // }
  }

  async verifyEmail() {
    this.errors = null;
    this.loading = true
    // UserId should not be email. This is incorrect.
    // Currently no self-verification on GUD so does not matter - this page is not used at all
    // but this and email template will need to be fixed if that is ever changed
    await Authentication.apiAccountConfirmEmailPost(
      { userId: this.confirmForm.email, code:this.code }
    )
      .then((res) => {
        if (res) {
          console.log("success verify", res);
          this.$message({ type: "success", message: 'Verification Successful.', duration: 3000 });
          this.$router.push({name: 'Login', query: {email: this.confirmForm.email}})
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        if(error.response.status == 400) {
          this.done = false
          this.code = ''
          this.$router.replace({name: 'EmailVerification'})
          handleError(error, true, "Token expired. Please enter your email address and resend your verification email.")
        } else {
          handleError(error, true, "An error occurred, please make sure you have entered your email correctly and try again.")
        }
      });
  }
}
